import React from "react";

// This function is a helper for when I'm dealing with React states in functional components.
// It works as a generic (see https://www.typescriptlang.org/docs/handbook/2/generics.html for more details), and can
// be used by calling the function like so:
//      State<string>("Hello World")
//      State<boolean>(false)
//      State<{a: string, b: number}>({a: "Hello", b: 123})
//
// The benefit of this is that I can then use type-checking in my functional components, as the state function
// will only accept values of a particular state. It should also throw an error if a value of a different type is inputted.
// To all intents and purposes, this acts as a normal `React.useState()` call, but just will type-checking on top.
// - Matt French 21-06-2021

export default function State<Type>(initialValue: Type): [Type, (value: Type) => void] {
    let [state, setState] = React.useState(initialValue);

    return [state, setState];
}

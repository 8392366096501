export const data_array = [{
    "formvalues": [
        null,
        "[true,true,false]"
    ],
    "Q_ID": 530,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
},
{
    "formvalues": [
        null,
        "[true,true,true,false]"
    ],
    "Q_ID": 531,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
},
{
    "formvalues": [
      null,
      "[false,false,true,false]"
    ],
    "Q_ID": 532,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
  },
  {
    "formvalues": [
        null,
        "It means the wave is parallel to the wave cycle"
    ],
    "Q_ID": 555,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
}, 
{
    "formvalues": [
        null,
        "Longitudal "
    ],
    "Q_ID": 535,
    "showingfeedback": false,
    "disabled": false,
    "first": null,
    "W_ID": 19
}, 
{
    "formvalues": [
        null,
        "sound"
    ],
    "Q_ID": 536,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
},
{
    "formvalues": [
      null,
      null,
      null,
      "[false,true,false,false,false]"
    ],
    "Q_ID": 538,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
  },
  {
    "formvalues": [
        null,
        null,
        null,
        "[true,false,false,false,false]"
    ],
    "Q_ID": 538,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
},
{
    "formvalues": [
        null,
        null,
        null,
        "[false,true,false,false,false]"
    ],
    "Q_ID": 539,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
},
{
    "formvalues": [
      null,
      null,
      null,
      "[false,false,false,false,true]"
    ],
    "Q_ID": 540,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
  },
  {
    "formvalues": [
      null,
      "distance between the peak and the undisturbed position "
    ],
    "Q_ID": 543,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
  },
  {
    "formvalues": [
      null,
      "Distance from crest to neighbouring crest "
    ],
    "Q_ID": 546,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
  },
  {
    "formvalues": [
      null,
      ""
    ],
    "Q_ID": 547,
    "first": 1,
    "W_ID": 19,
    "is_hint_request": true
  },
  {
    "formvalues": [
      null,
      " wave cycle how long it takes"
    ],
    "Q_ID": 547,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
  },
  {
    "formvalues": [
        null,
        "number of wave cycles per second"
    ],
    "Q_ID": 544,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
}, {
    "formvalues": [
        null,
        "who knows"
    ],
    "Q_ID": 545,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
}, {
    "formvalues": [
        null,
        "asdas"
    ],
    "Q_ID": 552,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
}, {
    "formvalues": [
        null,
        "A wavefront is a line or surface. \n\nWhat is special about this line. Or more specifically what is special about points oscillating on this line.\nA wavefront is a line or surface. \n\nWhat is special about this line. Or more specifically what is special about points oscillating on this line.\nA wavefront is a line or surface. \n\nWhat is special about this line. Or more specifically what is special about points oscillating on this line."
    ],
    "Q_ID": 548,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
}, {
    "formvalues": [
        null,
        "Be specific, refer to complete wave cycles. \n\nExplanation: a 'complete wave' or simply 'wave' can refer to the whole wave emitted by the source, from its start to its end, this would likely include many complete wave cycles.\n\nNote that Hz = s⁻¹ = per second."
    ],
    "Q_ID": 553,
    "showingfeedback": true,
    "disabled": false,
    "W_ID": 19
}, {
    "formvalues": [
        null,
        "λ "
    ],
    "Q_ID": 550,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
}, {
    "formvalues": [
        null,
        "T"
    ],
    "Q_ID": 551,
    "showingfeedback": false,
    "disabled": false,
    "first": 1,
    "W_ID": 19
}
]


import {Attempt, ClassAssignmentData, FeedbackObject, FieldDesc, ManScore, MarkerType, PupilNames, PupilWSData, PupilWsStats, QuestionData, UserType} from "./my_interfaces";
import {commonpasswords} from "./commonpasswords";
import { logger } from "express-winston";
import { json } from "body-parser";

export const symbolPanelSymbolsShared= ["√","÷","×","=","⟶","λ","ρ","η","Φ","Ω","θ","Δ","π","ε","μ","⁻","⁺","¹","²","³","⁴","⁵","⁶","⁷","⁸","⁹","⁰","₀","₁","₂","₃","₄"]//,"₅","₆","₇","₈","₉"];
export function isANumber(numberstring:string):boolean{
    let number = Number(numberstring);
    if(!isNaN(number)){ //isNaN stands for is not a number is !isNaN means is a number, need Number() because isNaN takes type number as input.
        let isnotdodgy = number!==0||numberstring.indexOf("0")>-1;//doesn't quite work because 0bla will cause problem. //values such as "", "\n" and " " and "null" can give 0, we want to ignore those by selecting non zero and those with actual "0" in
        if(isnotdodgy){
            return true
        }
    }
    return false
}
export function stringarraytolist(input:string[]):string{
    if(input.length===0){ return ""}
    else{
        let output:string = input[0];
        if(input.length===1){return output}
        else{
            for(let i=1;i<input.length-1;i++){
                output += ", "+input[i]
            }
            output += " and "+input[input.length-1]
            return output;
        }
    }
}
export function parsePartsValues(formvalue:string|undefined):string[]{
    if(formvalue===undefined){//throw "the formvalue is undefined for the input_numerical_twopart";
        //console.log("setting formvalue for input_numerical_twopart since it was undefined");
        formvalue=JSON.stringify(["",""]);//TODO don't think this does anything, think it is set here:create_empty_formvalues() in my_interfaces
    }
    let fieldpartsvalues:string[]=JSON.parse(formvalue);
    return fieldpartsvalues
    //console.log("fieldpartsvalues[0]"+fieldpartsvalues[0]);
}
export function isNumber(input:any):boolean{
    return !isNaN(Number(input))&&(Number(input)!==0||input.indexOf("0")>-1);//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number
}
export function loggy(object:any,name:string){
    console.log(name+": "+JSON.stringify(object,null,4));
}
export function copy(object:any):any{
    if(object===undefined){return undefined}
    return JSON.parse(JSON.stringify(object))
}
export function getpercentagescore(score:(number|null)[],maxscore:(number|null)[]):number{
    let tot_score = 0;
		let tot_possible_score = 0;
		for(let f=0; f<score.length; f++){
            if(maxscore[f]===undefined||score[f]===undefined) throw "maxscore and score should not have undefined elements (in getpercentagescore)"
			//let scoref = score[f];
			//let maxscoref = maxscore[f];
			tot_score += Number(score[f]);
			tot_possible_score += Number(maxscore[f]);
			//tot_possible_score += (maxscoref!=null)?maxscoref:0;
			//tot_score += score[f]|0;
			//tot_possible_score += maxscore[f]|0;
			//tot_possible_score += (maxscore[f]!=null)?score[f]:0;
        }
        if (tot_possible_score===0) throw "ERROR in getpercentagescore() tot_possible_score is zero"
		return tot_score/tot_possible_score;
}
/**
 * For each field, select the latest manual score if there is one, otherwise select the stored score
 * @param attempt 
 * @returns 
 */
export function getattemptmanscore(attempt:{score:(number|null)[]|undefined,manscore?:ManScore[]|undefined}):(number|null)[]{
    
	//create object to store score
	let newscore:(number|null)[] = [];

	//check for errors
    if(attempt.score===undefined) throw "attempt.score is undefined in getattemptmanscore, attempt score should be an array of nulls or a number, maybe a draft should be undefined attempt: "+JSON.stringify(attempt);
    
	//for each part of the question
	for(let f = 0 ; f<attempt.score.length; f++){
        
		//select the manual score if there is one, otherwise use the existing score
		newscore.push(attempt.score[f]);
        let bestmanscore = getfieldmanscore(attempt.manscore, f);
        if(bestmanscore!==null){
            newscore[f] = bestmanscore;
        }
    }
    return newscore;
}
export function getfieldmanscore(manscore:ManScore[]|undefined,field:number):number|null{
    if(manscore===undefined){
        return null;
    } else {
        let bestscore = manscore.find(m=>((m.marker_type==="teacher")&&(m.field===field)));
        if(bestscore===undefined){
            bestscore = manscore.find(m=>((m.marker_type==="admin")&&(m.field===field)));
            if(bestscore===undefined){
                return null;
            } else {
                return bestscore.score;
            }
        } else {
            return bestscore.score;
        }
    }
}
export function updateManscore(oldattempt:Attempt,marker_ID:number,marker_type:MarkerType,scorechange:number,field:number):Attempt{
	if(oldattempt.score===undefined){throw "score is undefined"}
	let field_score = oldattempt.score[field];
	if(field_score===undefined){throw "couldn't find field"}
	if(field_score===null){throw "field score is null"}
    let maxscore = oldattempt.maxscore[field];//how is the maxscore of a field set?
	if(maxscore===null){throw "field maxscore is null"}
    
	let oldmanscore:ManScore[]|undefined = oldattempt.manscore===undefined?undefined:JSON.parse(JSON.stringify(oldattempt.manscore));//bug because I tried to stringify and unstringify an undefined
	//look to see if there is a manscore that needs changing
	let newmanscore:ManScore = {
		marker_ID: marker_ID,   //who gave this manual mark
		marker_type: marker_type, //there isn't the ability for anyone else to mark yet, I might put self marking into this later
		mark_date: new Date(),    //when did they give the mark
		score: field_score+scorechange,//This deals with first clicks, later clicks are handled below
		field: field,       //which field was marked
	//	marking?: boolean[] //this is for questions where marks are awarded for individual marking points			
	}
	if(oldmanscore!==undefined){
		//there has been some scoring manual scoring of this attempt
		let oldmanscoreindex = oldmanscore.findIndex(m=>m.marker_ID===marker_ID);
		if(oldmanscoreindex===-1){
			//it wasn't the user currently trying to manscore
			oldmanscore.push(newmanscore);
		} else {
			//verified that the user trying to rescore has rescored
			//get the specific manscore record
			let oldmanscorerecord = oldmanscore[oldmanscoreindex];
			newmanscore.score = oldmanscorerecord.score+scorechange;
		//	logger.info("oldmanscore.score: "+oldmanscorerecord.score+" + scorechange = "+scorechange+" = newmanscore.score "+newmanscore.score);
			//oldmanscore=newmanscore;
			//logger.info("oldmanscore.score: "+oldmanscore.score+" newmanscore.score "+newmanscore.score);
			oldmanscore[oldmanscoreindex] = newmanscore;
		//	logger.info("oldmanscore: "+JSON.stringify(oldmanscore));
		}
	} else {
		oldmanscore = [newmanscore];
	}
    if(newmanscore.score>maxscore||newmanscore.score<0){//don't go above max or below 0.
        return oldattempt;
    }
	oldattempt.info.last_mod_date = new Date();
    oldattempt.manscore = oldmanscore;
	return oldattempt; //it should have been updated by now
}
function basic_info(pupil_index:number,value_type:string,worksheet_name:string,class_name:string,pupilsnames:PupilNames[]):string{
    let basic_info = '';
    basic_info += class_name
    basic_info += ","+worksheet_name
    basic_info += ","+pupilsnames[pupil_index].lastname
    basic_info += ","+pupilsnames[pupil_index].firstname
    basic_info += ","+pupilsnames[pupil_index].username
    basic_info += ","+value_type;
    return basic_info;
}
export function classdatatocsv(classassignmentdata:ClassAssignmentData,worksheet_name:string,class_name:string):string{
	let datacsv = '';//data:text/csv;charset=utf-8,%EF%BB%BF';
	//construct headers matches with basic_info
	datacsv += "class name"
	datacsv += ",worksheet"
    datacsv += ",lastname"
    datacsv += ",firstname"
    datacsv += ",username"
    datacsv += ",value type";
		//loop through questions adding a number
		for(let q = 0; q<classassignmentdata.questions.length; q++){
			datacsv += ",Q"+(q+1)
		}
		datacsv +=`
`;
        //add row with max scores
    datacsv +=",,,,,Out of: ";
        for(let q = 0; q<classassignmentdata.questions.length; q++){
            let qstats = classassignmentdata.questions[q].stats;
            if(qstats===undefined)throw "qstats undefined"
			datacsv += ","+qstats.out_of;
		}
		datacsv +=`
`;
		//construct body
        //first few info columns
        //best score
		//loop through users
		for(let u = 0; u<classassignmentdata.pupilsnames.length; u++){
            datacsv += basic_info(u,"best score",worksheet_name,class_name,classassignmentdata.pupilsnames);
			//loop through questions adding the value
			for(let q = 0; q<classassignmentdata.questions.length; q++){
				let stats = classassignmentdata.questions[q].pupils[u].stats;
				if(stats===undefined){ 
                    //throw "stat undefined"
                    datacsv += ",0";//+0;
                } else if (stats.max_score===undefined){
                    datacsv += ",0";
                } else {
                    datacsv += ","+stats.max_score;
                }
			}
			datacsv += `
`;
		}
        for(let u = 0; u<classassignmentdata.pupilsnames.length; u++){
            datacsv += basic_info(u,"initial score",worksheet_name,class_name,classassignmentdata.pupilsnames);
			//loop through questions adding the value
			for(let q = 0; q<classassignmentdata.questions.length; q++){
				let stats = classassignmentdata.questions[q].pupils[u].stats;
				if(stats===undefined){ 
                    //throw "stat undefined"
                    datacsv += ",0";//+0;
                } else if (stats.max_score===undefined){
                    datacsv += ",0";
                } else {
                    datacsv += ","+stats.initial_score;
                }
			}
			datacsv += `
`;
		}
        for(let u = 0; u<classassignmentdata.pupilsnames.length; u++){
            datacsv += basic_info(u,"num attempts",worksheet_name,class_name,classassignmentdata.pupilsnames);
			//loop through questions adding the value
			for(let q = 0; q<classassignmentdata.questions.length; q++){
				let stats = classassignmentdata.questions[q].pupils[u].stats;
				if(stats===undefined){ 
                    //throw "stat undefined"
                    datacsv += ",0";//+0;
                } else if (stats.max_score===undefined){
                    datacsv += ",0";
                } else {
                    datacsv += ","+stats.num_attempts;
                }
			}
			datacsv += `
`;
		}
        for(let u = 0; u<classassignmentdata.pupilsnames.length; u++){
            datacsv += basic_info(u,"best attempt",worksheet_name,class_name,classassignmentdata.pupilsnames);
			//loop through questions adding the value
			for(let q = 0; q<classassignmentdata.questions.length; q++){
				let stats = classassignmentdata.questions[q].pupils[u].stats;
				let attempts = classassignmentdata.questions[q].pupils[u].attempts;
                let question = classassignmentdata.questions[q];
                let f = question.field_desc.findIndex((field:FieldDesc)=>field.isinput)//TODO only works if there is only one input field
                if(f===-1) throw "couldn't find input field";
				if(stats===undefined){ 
                    //throw "stat undefined"
                    datacsv += ",";//+0;
                } else if (stats.max_score===undefined){
                    datacsv += ",";
                } else {
                    let best_attempt_index = attempts.findIndex((a:Attempt)=>{
                        if(stats===undefined){ 
                            console.log("this totally shouldn't happen");
                            throw "this totally shouldn't happen"
                        }
                        if(a.score===undefined) {throw "a.score is undefined"}
                        return a.score[f]===stats.max_score;
                    });
                    let display_answer = 'bob';
                    if(best_attempt_index===-1){
                        console.log("best_attempt_index: "+best_attempt_index);
                        throw "I shouldn't be here"
                    } else {
                        console.log("best_attempt_index: "+best_attempt_index);
                        let best_attempt = attempts[best_attempt_index];
                        display_answer = ''+best_attempt.useranswer[f];//We have the beginning so an error is thrown if we even change the type of useranswer[f] e.g. to be an object, otherwise we will get js overwriting /pass by reference problems.
						//I thought that I might make it so that the checkbox things are visible. But actually I don't have the question option text available. I would need to pull that through.
						if(display_answer!==undefined&&display_answer!==null&&display_answer!==''){
                            if(question.field_desc[f].type==="checkbox"){
                                display_answer = formatcheckboxanswer(display_answer);
							} else if(question.field_desc[f].type==="input_numerical_twopart"){
                                display_answer = formatnumericalanswer(display_answer);
							} else if(question.field_desc[f].type==="input_self_mark"){
                                display_answer = formatselfmarkanswer(display_answer);
							} else if(question.field_desc[f].type==="input_text_long"){
                                display_answer = formatlongtextanswer(display_answer);
							}
						}
                        display_answer = display_answer.replace(/\"/g,"'");
                    }
                    datacsv += ',"'+display_answer+'"';//problem if the student uses quotes, I should remove quotes from their answers. 
                }
			}
            //adding new line
			datacsv += `
`;
		}
        //return
        datacsv = datacsv.replace(/(^[^"]*,) */g,"$1");
        datacsv = datacsv.replace(/("[^"]*",) */g,"$1");
	return datacsv;
}
export function formatcheckboxanswer(display_answer:string):string{
	let boolean_array = JSON.parse(display_answer);
	let typed_answer = [];
	for (let o = 0; o<boolean_array.length; o++){
		typed_answer.push(boolean_array[o]?" ☑":" ☐");
	}
	display_answer = '['+typed_answer+"]";
	return display_answer;
}
export function formatnumericalanswer(display_answer:string):string{
	let twopartarray = JSON.parse(display_answer);
	let typed_answer = twopartarray[0]+`
ans: `+twopartarray[1];
	display_answer = typed_answer.replace(/\n/g,`
`)
	return display_answer;
}
export function formatselfmarkanswer(display_answer:string):string{
	let twopartarray = JSON.parse(display_answer);
	let typed_answer = twopartarray[0]+`
marked as: `+formatcheckboxanswer(twopartarray[1]);
	display_answer = typed_answer.replace(/\n/g,`
`)
	return display_answer;
}
export function formatlongtextanswer(typed_answer:string):string{
	if(typed_answer.length>1000){
		typed_answer = typed_answer.slice(0,999)+" ... too long."
	}
	typed_answer = typed_answer.trimRight();
	return typed_answer.replace(/[\s\n\r]+$/g,"");
}

export function scorePassword(pass:string):number {//TODO the use of any here and below is a complete hack yikes!
    console.log("scorePassword pass: "+pass);
    var score = 0;
    if (!pass)
        return score;

    // replace commonpasswords with their first and last character
    for (let i=0;i<commonpasswords.length;i++){
        if(pass.search(commonpasswords[i])>-1){
            console.log("replacing: "+commonpasswords[i]+" with "+commonpasswords[i][0]+commonpasswords[i][commonpasswords[i].length-1]+" in "+pass);
            pass = pass.replace(commonpasswords[i],commonpasswords[i][0]+commonpasswords[i][commonpasswords[i].length-1]);
            console.log("new pass:"+pass);
        }
    }
    //replace username TODO:tricky as we don't currently have the username

    // award every unique letter until 5 repetitions
    let letters:any = new Object();
    for (let i=0; i<pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    let variations:any = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    let variationCount = 0;
    for (let check in variations) {
        variationCount += (variations[check] == true) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    console.log("password score: "+score);
    return score;//parseInt(score);
}

export function checkPassStrength(pass:string) {//can just call this one.
    var score = scorePassword(pass);
    if (pass.length<8) {return "too short";}
    else {
        if (score > 80)
            return "strong";
        if (score > 60)
            return "good";
        if (score > 45)
            return "ok";
        if (score >= 30)
            return "too weak";
    }
    return "far too weak";
}
export async function getFeedback(formvalues:(string|null|undefined)[], Q_ID:number):Promise<{head:string[],body:FeedbackObject[][]}>{
	let url = '/viewfeedback';
	let data = {formvalues,
			question:undefined,//may not have a question ID yet - I should be saving progress
			Q_ID
		};
	let res = await fetch(url, {
		method: 'POST', // or 'PUT'
		body: JSON.stringify(data), // data can be `string` or {object}!
		headers:{
			'Content-Type': 'application/json'
		}
		})
	return res.json();
}
/**
 * returns last reset date in list of reset dates. 
 * If there is no reset date then it returns undefined.
 * @param classassignmentdata
 * @param p 
 * @returns 
 */
function getLastResetDate(classassignmentdata:ClassAssignmentData,p:number):(Date)|undefined{
	//console.log("getLastResetDate started")
	let reset_dates = getResetDates(classassignmentdata,p)
	if (reset_dates===undefined){
		return undefined
	} else {
		return reset_dates[reset_dates.length-1];//haven't tested this yet
	}
}
function getResetDates(classassignmentdata:ClassAssignmentData,p:number):(Date)[]|undefined{
	if(classassignmentdata.pupilswsstats===undefined){//interesting, may have just not calculated it yet?
		console.log("pupilswsstats in classassignmentdata is undefined, so can't get reset date");
		return undefined;//[new Date(0)];
	} else {
		let resetdates = classassignmentdata.pupilswsstats[p].reset_dates
		if(resetdates == undefined){
			console.log("resetdates undefined")
			return undefined;//[new Date(0)]
		}
		else {
			return resetdates;
		}
	}
}
export function calcScores(classassignmentdata:ClassAssignmentData):ClassAssignmentData{

	console.log("calcScores called");

	let questions = classassignmentdata.questions;

	let worksheet_out_of = 0;

	//for each question
	for(let q=0;q<questions.length;q++){

			//console.log("looking at question: "+q+" ID "+questions[q].Q_ID);

		//get and store max score for question, add to worksheet max
		let	q_max_pos_score = getQMaxPosScore(questions[q]);
		questions[q].stats={
			out_of:q_max_pos_score,
		}
		worksheet_out_of += q_max_pos_score;

		//for each pupil
		for (let p=0;p<questions[q].pupils.length;p++){

			let reset_date = getLastResetDate(classassignmentdata,p);
		//	console.log("reset_date: "+reset_date);
		//	console.log("looking at pupil "+p+", name: "+questions[q].pupils[p].username);
			//TODO - something to do with username?
			//let worksheet_attempted_out_of = 0;
			//let worksheet_max_score_attained = 0;
			//let worksheet_adjusted_score_attained = 0;
		//	console.log("questions[q].pupils[p].attempts"+JSON.stringify(questions[q].pupils[p].attempts));
			let initial_percentage = null;
			let adjusted_percentage = null;
			let max_percentage = null;
			let reset_percentage = null; //percentage complete after reset
			let resetwoh_percentage = null; //percentage complete after reset without help
			//if they've answered the question
			if(questions[q].pupils[p].attempts.length>0){
				let [max_score,adjusted_final_score,initial_score,last_mod_date,wohelp_score,reset_score,resetwoh_score]=getQScore(questions[q].pupils[p].attempts,reset_date);
				//reset_score is the score since last reset. If they have never reset then it should be the same as max_score// hmm I think it should be zero

		//		worksheet_attempted_out_of += q_max_pos_score;
		//		worksheet_max_score_attained += max_score;
		//		worksheet_adjusted_score_attained += adjusted_final_score;
				initial_percentage = initial_score/q_max_pos_score;
				adjusted_percentage = adjusted_final_score/q_max_pos_score;
				max_percentage = max_score/q_max_pos_score;
				reset_percentage = reset_score===null?undefined:(reset_score/q_max_pos_score);
				resetwoh_percentage = resetwoh_score===null?undefined:(resetwoh_score/q_max_pos_score);
				questions[q].pupils[p].stats={
					...questions[q].pupils[p].stats, // Spread operator to copy existing properties
					initial_score,
					adjusted_final_score,
					max_score,
					wohelp_score,
					reset_score : reset_score===null?undefined:reset_score,
					resetwoh_score : resetwoh_score===null?undefined:resetwoh_score,
					initial_percentage,
					adjusted_percentage,
					max_percentage,
					reset_percentage,//this allows us to colour buttons in exercises page
					resetwoh_percentage, //this allows summary page to work after worksheet reset. 
					num_attempts:questions[q].pupils[p].attempts.length,
					last_mod_date:last_mod_date,//so we know what attempt was included in the last calc
					//last_calc_date: new Date(),//so we know when we last did a calc this should be stored on the level above
				}
			} else {
				questions[q].pupils[p].stats={
					...questions[q].pupils[p].stats, // Spread operator to copy existing properties
					adjusted_final_score:undefined,
					max_score:undefined,
					adjusted_percentage:undefined,
					max_percentage:undefined,
					reset_percentage:undefined,
					resetwoh_percentage:undefined,
					num_attempts:0,
					last_mod_date: new Date(0),
				}
			}//question has not been attempted
		}
	}
	classassignmentdata.stats={
		out_of:worksheet_out_of,
	}
	//console.log("before calcScores reset_dates: "+JSON.stringify(classassignmentdata.pupilswsstats,null,4))
	classassignmentdata = calcpupilwsstats(classassignmentdata);
	//console.log("after calcScores reset_dates: "+JSON.stringify(classassignmentdata.pupilswsstats,null,4))
	//console.log("calcScores classassignmentdata: "+JSON.stringify(classassignmentdata,null,4));
	return classassignmentdata;
}
function calcpupilwsstats(classassignmentdata:ClassAssignmentData):ClassAssignmentData{
	console.log("calcpupilwsstats called");
	let questions = classassignmentdata.questions;
	//let reset_dates_array = [];
	//for(let p=0; p<questions[0].pupils.length;p++){//TODO less dodge way of getting number of pupils required
	//	reset_dates_array
	//}
	if(classassignmentdata.pupilswsstats === undefined){
		classassignmentdata.pupilswsstats = [];//TODO this would be ok if din't stupidly put the username and the reset dates in pupilswsstats
	}
	//console.log("in calcScores reset_dates: "+JSON.stringify(classassignmentdata.pupilswsstats,null,4))
	if(questions.length===0){
		throw "num questions in this worksheet is zero"
		console.log("num questions in this worksheet is zero");
	}//maybe the below should work of class assignment data instead of a question. 
	let firstquestion = questions[0];
	if(firstquestion===undefined) {
		throw "no first question"
		//	console.log("no first question")
		//	return classassignmentdata
	}
	if(firstquestion.pupils ===undefined){
		throw "no pupils in first question"
		//	console.log("no pupils in first question")
		//	return classassignmentdata
	}
	for (let p=0;p<questions[0].pupils.length;p++){//TODO less dodge way of getting number of pupils required
		//	console.log("looking st pupil "+p+", name: "+questions[0].pupils[p].username);
		//loop through questions
		let username = classassignmentdata.pupilsnames[p].username;
		let reset_dates = undefined;
		if(classassignmentdata.pupilswsstats[p]!==undefined){
			reset_dates = classassignmentdata.pupilswsstats[p].reset_dates;
		}
		//classassignmentdata.pupilswsstats[p] = upDateResetDateStats(classassignmentdata.pupilswsstats[p],username);
		//username:string,
		let pupilsws={//}:PupilWSData = {//TODO this is dodgy because if I add new properties like I did with reset dates then they will be erased. 
			username,
			reset_dates,//:getResetDates(classassignmentdata,p) getResetDates makes reset date of 0 if its not there, this is bad because I elsewhere check for reset dates length being 0 or not to indicate if it has been reset
			initial_score:0,
			max_score:0,
			wohelp_score:0,
			reset_score:0,
			resetwoh_score:0,
			adjusted_final_score:0,
			attempted_out_of:0,
			initial_percentage:0,
			max_percentage:0,
			wohelp_percentage:0,
			adjusted_percentage:0,
			attempted_adjusted_percentage:0,
			reset_percentage:0,
    		resetwoh_percentage:0,
			num_attempts:0,
			last_mod_date:new Date(0),//TODO not sure why this is being set to the start of time. 
			last_calc_date:new Date(),
		}
		for(let q=0;q<questions.length;q++){
		//	console.log("looking at question: "+q);
			let s = questions[q].pupils[p].stats;//TODO not sure what this PupilData thing in interfaces is about
			if(s===undefined) throw "s is undefined"
			let qs = questions[q].stats;
			if(qs===undefined) throw "qs is undefined"
			let hasattemptedquestion:boolean = ((s.num_attempts||0)>0)?true:false;
			pupilsws.initial_score			+=(s.initial_score||0);
			pupilsws.adjusted_final_score	+=(s.adjusted_final_score||0);
			pupilsws.max_score				+=(s.max_score||0);
				//maybe the below should be done at question level.
			//pupilsws.wohelp_score			+=(Math.max((s.wohelp_score||0),(s.initial_score||0)));//wohelp can happen for the initial or when they do the summary question. 
			pupilsws.wohelp_score			+=(s.wohelp_score||0);
			pupilsws.reset_score			+=(s.reset_score||0);
			pupilsws.resetwoh_score			+=(s.resetwoh_score||0);
			pupilsws.attempted_out_of		+=hasattemptedquestion?qs.out_of||0:0;
			pupilsws.num_attempts			+=s.num_attempts||0;
			pupilsws.last_mod_date			=(pupilsws.last_mod_date>=(s.last_mod_date||new Date(0)))?pupilsws.last_mod_date:(s.last_mod_date||new Date(0));
		}
		let cs = classassignmentdata.stats;
		if(cs===undefined) throw "s is undefined"
		if(cs.out_of===undefined||cs.out_of===null||cs.out_of===0){
			throw "cs.out_of not properly calculated";
		} else{
			pupilsws.initial_percentage = pupilsws.initial_score/cs.out_of;
			pupilsws.max_percentage = pupilsws.max_score/cs.out_of;
			pupilsws.wohelp_percentage = pupilsws.wohelp_score/cs.out_of;
			pupilsws.adjusted_percentage = pupilsws.adjusted_final_score/cs.out_of;
			pupilsws.attempted_adjusted_percentage = pupilsws.adjusted_final_score/pupilsws.attempted_out_of;
			pupilsws.reset_percentage = pupilsws.reset_score/cs.out_of;
			pupilsws.resetwoh_percentage = pupilsws.resetwoh_score/cs.out_of;
		}
		classassignmentdata.pupilswsstats[p] =pupilsws; //cannot believe this was push!
	}
	return classassignmentdata
}
export function toCamelCase(input:string):string{
	return input.substring(0,1).toLocaleUpperCase() + input.substring(1);
}
export function upDateResetDate(pupilwsstat:PupilWsStats|undefined,username:string):PupilWsStats{
	//logger.info("upDateResetDate start")
	if(pupilwsstat!==undefined){
			pupilwsstat.stats = upDateResetDateStats(pupilwsstat.stats,username)
		return pupilwsstat;
	} else {
		throw "clicked reset but there is no pupilwsstat"
	}
	//logger.info("upDateResetDate end")
}
export function upDateResetDateStats(pupilwsdata:PupilWSData|undefined,username:string):PupilWSData{
		if(pupilwsdata!==undefined){
			if(pupilwsdata.reset_dates==undefined){
				pupilwsdata.reset_dates=[new Date()]
			} else {
				pupilwsdata.reset_dates.push(new Date())
			}
		} else {
			pupilwsdata = {
				reset_dates :[new Date()],
				username,
			}
		}
		return pupilwsdata;
}
function getQMaxPosScore(questiondata:QuestionData):number{
	let maxposqscore = 0;
	for (let f=0;f<questiondata.field_desc.length;f++){
		let weighting = questiondata.field_desc[f].weighting;
		if(weighting!==undefined){
			maxposqscore += weighting;
		} else {
			maxposqscore += questiondata.field_desc[f].maxscore;
		}
	}
	return maxposqscore;
}
/**
 * 
 * @param attempts 
 * @param reset_date 
 * @returns 
 */
function getQScore(attempts:Attempt[],reset_date:Date|undefined):[number,number,number,Date,number,null|number,null|number]{
	let max_score = 0;
	let adjusted_final_score = 0;
	let initial_score = 0;
	let wohelp_score = 0;
	let reset_score:number|null = 0;
	let resetwoh_score:number|null = 0;
	let numsubmittedafterreset = 0;
	let last_mod_date:Date = new Date(0);//attempts[attempts.length-1].info.last_mod_date;

	for(let a=0;a<attempts.length;a++){
		//first thing is to update the last mod date to the most recent attempt
		if (new Date(attempts[a].info.last_mod_date)>last_mod_date){
			last_mod_date = new Date(attempts[a].info.last_mod_date);
		}
		
		//skip unmarked drafts
		if(attempts[a].score===undefined&&attempts[a].status==="draft"){
			continue
		}
		//skip unmarked submitted questions, something has gone wrong but we probably want to not fail the whole page
		if(attempts[a].score===undefined&&attempts[a].status==="submitted"){
			console.log("Error: a submitted attempt is not marked, attempt: "+JSON.stringify(attempts[a]));//TODO send some error message to me
			continue
		}
	
		//remove nulls from score array
		//let score = attempts[a].score;
		let score = getattemptmanscore(attempts[a]);
		if(score===undefined) throw "score is undefined"
		let question_part_scores:number[] = score.map(e=>e===null?0:e);
		//add up score for parts of question
		let attempt_score = question_part_scores.reduce((a:number,b:number)=>a+b,0);
		if(a===0){
			initial_score += attempt_score;
		}
		if(a<5){//consider the first 5 attempts
			//let attempt_multiplier = (1-0.25*a);
			let attempt_multiplier = Math.pow(0.5,a); //1, 0.5, 0.25, 0.125,
			let mark_improvement = Math.max(attempt_score-max_score,0);
			let adjusted_improvement = mark_improvement*attempt_multiplier;
			adjusted_final_score += adjusted_improvement;
		}
		if((attempts[a].first===1)){
			wohelp_score = Math.max(wohelp_score,attempt_score);
		}
		//console.log("attempt last mod date is: "+attempts[a].info.last_mod_date.valueOf());
		//console.log("attempt reset_date is: "+attempts[a].info.last_mod_date.valueOf());
	//	if(attempts[a].Q_ID===552 && a>(attempts.length-3)){
	//		console.log("attempt last mod date is: "+attempts[a].info.last_mod_date.valueOf());
	//		console.log("attempt reset_date is: "+reset_date.valueOf());
	//		console.log("date parse attempt reset_date is: "+Date.parse(reset_date.toString()));
	//		if(Date.parse(attempts[a].info.last_mod_date.toString())>Date.parse(reset_date.toString())){
	//			console.log("comparison fail");
	//		}
	//	}
		if(reset_date!==undefined){
			if(new Date(attempts[a].info.last_mod_date)>new Date(reset_date)){
				console.log("found attempt after reset date");
				//I need to se reset_score to null when there have been no attempts since red so that question number buttons aren't red when there have been no attempts since
				if(attempts[a].status==="submitted"){ //TODO I'm not sure if all of them coming here are filtered to be submitted in which case this is redundant, or if they are not in which case perhaps I need to add this if filter to other stat calculations
					console.log("found submitted attempt after reset date");
					reset_score = Math.max(reset_score,attempt_score);//this was missing!
					numsubmittedafterreset++;
				}
				reset_score = Math.max(reset_score,attempt_score);
				if((attempts[a].first===1||a===0)){
					resetwoh_score = Math.max(resetwoh_score,attempt_score);
				}
			}
		}
		
		max_score = Math.max(max_score,attempt_score);
	}
	wohelp_score = Math.max(wohelp_score,initial_score); //could I just put ||a===0 where I have if((attempts[a].first===1)){?
	reset_score = numsubmittedafterreset>0?reset_score:null
	resetwoh_score = numsubmittedafterreset>0?resetwoh_score:null
	return [max_score,adjusted_final_score,initial_score,last_mod_date,wohelp_score,reset_score,resetwoh_score]
}
export async function changeAttemptScore(attempt:Attempt, field:number, scorechange:number){
	console.log('changeAttemptScore for A_ID, field, by'+attempt.A_ID+" "+field+" "+scorechange);
	var url = '/changeAttemptScore';
	var data = {A_ID:attempt.A_ID,field,scorechange};
	let response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify(data),
		headers:{'Content-Type': 'application/json'	}
	});
//	let js_obj = await response.json();
//	return js_obj.success;
}
//export async function isFieldvalueNew(fieldname:string,fieldvalue:string): Promise<boolean> {
//    console.log('checking if '+ fieldname+' is used');
//    var url = '/checkisnew';
//    var data = {fieldtocheck:fieldname,
//        [fieldname]:fieldvalue};
//    let response = await fetch(url, {
//        method: 'POST',
//        body: JSON.stringify(data),
//        headers:{'Content-Type': 'application/json'	}
//    });
//    let js_obj = await response.json();
//    console.log("returning isnew: "+js_obj.isnew);
//    return js_obj.isnew;
//}
export function elementsAreNotTrue(anarray:any[]):boolean{
	for(let i = 0; i<anarray.length; i++){
		if(anarray[i]==true){
			return false
		}
	}
	return true
}
/**
* Returns the index of the last element in the array where predicate is true, and -1
* otherwise.
* @param array The source array to search in
* @param predicate find calls predicate once for each element of the array, in descending
* order, until it finds one where predicate returns true. If such an element is found,
* findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
*/
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array))
            return l;
    }
    return -1;
}
export const PUPIL_MANAGER = '/img/PupilManagerDemo.png';   // Image displayed in second info section
export const HERO_IMAGE = '/img/MyMarkingMachine.png';      // Image displayed in the hero
//export const QUESTION_DEMO = '/img/FeedbackDemoShort.png';  // Image displayed in the first info section
export const QUESTION_DEMO = '/img/FeedbackDemoShort.png';  // Image displayed in the first info section
export const NUMERICAL_QUESTIONS = '/img/numerical_question.PNG';
export const LONGER_QUESTIONS = '/img/Radio_waves_capture.PNG';
export const SAVE_TIME = '/img/summary_page.png';
export const FOR_HOMEWORK = '/img/highlighted_markbook.PNG';
//export const DIVE_DEEPER = '/img/close_up_of_pupil_work.png';
export const DIVE_DEEPER = '/img/close_up_of_pupil_work2.PNG';
export const IN_CLASS = '/img/croppedgidclassprogress.gif';
export const SEND_ICON = '/img/send_icon.png'
export const MINIMISED_KEY = '/img/minimised_key.png';
//BUG - '/img/highlighted_markbook.png' didn't find file '/img/highlighted_markbook.PNG'
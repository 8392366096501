import { data_array } from "./data_for_load_testing";

export function loadtest1(){
    let j = 0; 
    let i = 0;
    while (j<200) {
        let data = data_array[i];
        saveanswer(data);
        i = (i + 1) % data_array.length; // Increment i and wrap around when it reaches the end
        j++;
    }
    

}
//then

//make a submission


function saveanswer(data:any){

    let url = '/saveanswer';

    //let data = {
    //    formvalues:this.state.formvalues,// Object.assign({}, this.state.formvalues),
    //    Q_ID:this.props.question.Q_ID,
    //    showingfeedback:this.state.showingfeedback,
    //    disabled:this.state.disabled,
    //    first:this.state.first,
    //    W_ID:this.props.W_ID,
    //};

    fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers:{
            'Content-Type': 'application/json'
        }
    })
}

//function submitanswer(){
//    let data = {
//        "formvalues": [
//          null,
//          "Distance from medium rest position to crest. "
//        ],
//        "Q_ID": 543,
//        "W_ID": 19,
//        "is_hint_request": false
//      }
//    
//}
//
//function  fetchFeedback(url:string,data:any,is_hint_request:boolean,call_updateQButtonColour:boolean){
//    console.log("fetchFeedback, is_hint_request: "+is_hint_request);
//    fetch(url, {
//        method: 'POST', // or 'PUT'
//        body: JSON.stringify(data), // data can be `string` or {object}!
//        headers:{
//            'Content-Type': 'application/json'
//        }
//        })

function getSomeDataForLoadTesting():any{
    return data_array
}
  
import React from 'react';
import {Button, Grid, IconButton, makeStyles, Theme, Typography} from "@material-ui/core";
import * as COLORS from "@material-ui/core/colors";
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
// REF_26472
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        // Holds all the content in the footer, use this for positioning and background colour
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: "100%",
        backgroundColor: COLORS.grey[900],
    },
    contentContainer: {
        maxWidth: "1300px",
        margin: "auto",
        [theme.breakpoints.between("xs", "sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.up("sm")]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6)
        }
    },
    text: {
        // Applied to the text in the footer, use this for font changes and text colour
        color: "white",
        textAlign: "center",
        fontSize: 16,
        letterSpacing: 0.8,
        [theme.breakpoints.down("sm")]: {
            display: "none"
        },
    },
    buttonContainer: {
        // Holds the button, use this for positioning
        margin: "auto",
        width: "max-content",
        marginTop: theme.spacing(1),
        marginBottom: -theme.spacing(1)
    },
    contactText: {
        color: "white",
        fontSize: 16,
    },
    contactIcon: {
        color: "white"
    },
    privacyButton: {
        ...theme.typography.h6,
        fontSize: 16,
        textTransform: "none",
        color: "white",
        borderColor: "white"
    }
}));


function Footer() {
    // We store all the CSS classes in the variable `classes`
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Grid container justify="space-between" alignItems="center" className={classes.contentContainer}>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item>
                            <Typography variant="h6" className={classes.contactText}>
                                Contact Us:
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="email" className={classes.contactIcon} onClick={() => window.open("mailto:someone@yoursite.com", "_blank")} /* REF_97088 */>
                                <EmailIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="twitter" className={classes.contactIcon} onClick={() => window.open("https://twitter.com/mark_robo", "_blank")} /* REF_52547 */>
                                <TwitterIcon/>
                            </IconButton>
                        </Grid>
                        {/* This is where you can add new contact buttons REF_78072*/}
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="h6" className={classes.text}>
                        {/* Change text here REF_14374 */}
                        My Marking Machine is still in active development<br/>Please bear with us as we improve the website
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="outlined" className={classes.privacyButton} onClick={() => window.open("https://docs.google.com/document/d/e/2PACX-1vQQzK_DcfLY4FsS6HgVnx4WGupWG2FUURTG4QP39NTe2vVv-nDA1HZZz55iFPquEZ7H1lT_erZZY10q/pub", "_blank")}>
                        Privacy
                    </Button>
                </Grid>
            </Grid>

            {/* TODO Implement Feedback button
            <div className={classes.buttonContainer}>
                <Button variant="text" color="primary" startIcon={<ErrorOutlineIcon/>}>
                    Give Feedback
                </Button>
            </div>
            */}
        </div>
    )
}


export default Footer;

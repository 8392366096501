import React from "react";
import {
    Avatar,
    Container,
    CssBaseline,
    Grid,
    Link,
    makeStyles,
    TextField,
    Theme,
    Typography
} from "@material-ui/core";
import { Link as RouterLink, withRouter} from 'react-router-dom';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {LoginPageProps} from "./_LoginPageInterfaces";
import {LoadingButton, OverrideBackground, State} from "../Utils";
import {Login} from "../../../App";//bit dodgy because this refers to two files
//import {Login} from "../../shared_files/shared_functions";


// This is kind of similar to CSS, it allows you to define classes for components in JavaScript
// that are then converted into CSS when the application is compiled.
const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    link: {
        cursor: "pointer",
        userSelect: "none",     // This prevents the text from being selected https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
    },
    textField: {
        margin: 0,
    }
}));


function LoginPage({onLoginSuccess, handleLogin, cookiesdisabled, history}: LoginPageProps) {
    const classes = useStyles();

    // Stores the Username entered into the text field
    const [username, setUsername] = State<string>("");

    // Stores the Password entered into the text field
    const [password, setPassword] = State<string>("");

    // Stores the message that tells the user what went wrong
    const [errors, setErrors] = State<{username: string, password: string}>({
        username: "",
        password: "",
    });

    // Handles the button loading wheel
    const [loading, setLoading] = State<boolean>(false);
    const [success, setSuccess] = State<boolean>(false);

    // Determines whether the button should be clickable or not
    const validateForm =  () => {
        return username !== "" && password !== "";
    }

    // This function is used to generate a function that will be passed to the OnChange property
    // of the text fields. To use it, pass one of the React state functions to it.
    const onChange = (func: Function) => {
        return (e: React.ChangeEvent<HTMLInputElement>) => func(e.target.value)
    }

    // Note from Matt:
    // I've lifted this functionality straight out the previous Component,
    // the only changes I've made is to make it use .then rather than await
    const attemptLoginold = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        setLoading(true);
        setErrors({username: "", password: ""});

        console.log('Attempting log in');

        Login(username, password)
            .then((js_obj) => {
                console.log("Login successful, calling onLoginSuccess")

                setLoading(false);
                setSuccess(true);

                if(js_obj.usertype === undefined) throw "INVALID LOGIN";
                // onLoginSuccess(js_obj.usertype, false);

            })
            .catch((error) => {
                console.log(`Login unsuccessful, failed with reason: "${error}"`);
                setLoading(false);
                setSuccess(false);

                let msg;
                if (error === "INVALID LOGIN") {
                    msg = `Invalid Username or Password. 

If you haven't used mymarkingmachine.com before, you need to click 'Get Started'.

You can try logging in using your email or your username. 

Students: you can ask your teacher to reset your password.

Teachers: you can ask mark@mymarkingmachine.com to reset your password.`;
                } else {
                    msg = "There was an issue trying to log you in, please try again in 1-2 minuntes. Contact mark@mymarkingmachine.com if the problem lasts longer than that.";
                }

                setErrors({username:" ", password: msg})
            });
    };
    const attemptLogin = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        setLoading(true);
        setErrors({username: "", password: ""});

        console.log('Attempting log in');

        await handleLogin(username, password)
            .then((user) => {
                // console.log("Login successful, calling onLoginSuccess")

                setLoading(false);
                setSuccess(true);
                history.push('/')
                // onLoginSuccess(js_obj.usertype, false);

            })
            .catch((error) => {
                console.log(`Login unsuccessful, failed with reason: "${error}"`);
                setLoading(false);
                setSuccess(false);

                let msg;
                if (error.message === "Incorrect credentials.") {
                    msg = `Invalid Username or Password. 

If you haven't used mymarkingmachine.com before, you need to click 'Get Started'.

You can try logging in using your email or your username. 

Students: you can ask your teacher to reset your password.

Teachers: you can ask mark@mymarkingmachine.com to reset your password.`;
                } else {
                    msg = "There was an issue trying to log you in, please try again in 1-2 minuntes. Contact mark@mymarkingmachine.com if the problem lasts longer than that.";
                }

                setErrors({username:" ", password: msg})
            });
    };
    return (
        <Container component="main" maxWidth="xs">
            <OverrideBackground/>
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Log in
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2} direction="column">
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username\Email"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={username}
                                onChange={onChange(setUsername)}
                                error={errors.username !== ""}
                                helperText={errors.username}
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={onChange(setPassword)}
                                error={errors.password !== ""}
                                helperText={errors.password}
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                text="Log In"
                                loading={loading}
                                valid={validateForm()}
                                success={success}
                                fail={false}
                                onClick={attemptLogin}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Link variant="body2" className={classes.link} component={RouterLink} to={'/register'}>
                                {"First time on My Marking Machine? Click here!"}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    )
}

export default withRouter(LoginPage)
